import { render, staticRenderFns } from "./WallLeft.vue?vue&type=template&id=f695b84c&"
import script from "./WallLeft.vue?vue&type=script&lang=js&"
export * from "./WallLeft.vue?vue&type=script&lang=js&"
import style0 from "./WallLeft.vue?vue&type=style&index=0&id=f695b84c&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
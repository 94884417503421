<template>
    <div class="villageTile">
        <img src="../../../assets/worldmap/SelectedVillage.png"/>
    </div>
</template>

<script>
    /* eslint-disable no-console */

    export default{}
</script>


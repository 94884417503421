<template>
    <div class="tileWorldMap">
        <img src="../../../assets/worldmap/Water.png"/>
    </div>
</template>

<script>
    /* eslint-disable no-console */

    export default{}
</script>

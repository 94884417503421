<template>
    <div>
        <img class="tileImg" src="../../assets/tiles/blank_spot.png"/>
    </div>
</template>

<script>
    /* eslint-disable no-console */

    export default{
        props: ['buildingProperties'],
    }
</script>

<style>

</style>

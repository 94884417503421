<template>
    <div class="villageTile">
        <img src="../../../assets/worldmap/OwnVillage.png"/>
    </div>
</template>

<script>
    /* eslint-disable no-console */

    export default{}
</script>

<style lang="scss">
    .villageTile{
        cursor: pointer;
        img{
            width: 40px;
            height: 40px;
        }
    }
</style>
